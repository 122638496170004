import { Controller } from "@hotwired/stimulus"
import Rails          from "@rails/ujs"

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ["inputName", "contact", 'searchForm', 'searchConversationForm', 'conversationsList']

  connect() {
    document.body.style.overflow = 'hidden'
  }

  selectedCount() {
    let count = this.contactTargets.filter(contact => contact.checked).length
    if (count > 1) {
      this.inputNameTarget.classList.remove('scale-x-0')
      this.inputNameTarget.classList.add('scale-x-1')
    } else {
      this.inputNameTarget.classList.remove('scale-x-1')
      this.inputNameTarget.classList.add('scale-x-0')
    }
  }

  searchUsers() {
    const url = `${this.searchFormTarget.action}?query=${event.currentTarget.value}`
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: data => {
        this.contactTargets.forEach((target) => {
          if (data.ids.includes(parseInt(target.value, 10))) {
            target.parentNode.classList.remove('hidden')
          } else {
            target.parentNode.classList.add('hidden')
          }
        })
      }
    })
  }

  searchConversations(event) {
    const url = `${this.searchConversationFormTarget.action}?query=${event.currentTarget.value}`
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: data => {
        this.conversationsListTarget.innerHTML = data.conversations
      }
    })
  }
}
