import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['icon', 'dropdown']

  connect() {
    this.addClickOut(this.dropdownTarget, this.iconTarget);
  }

  toggleDropdown() {
    this.iconTarget.classList.toggle('rotate-0')
    this.iconTarget.classList.toggle('rotate-180')
    if (this.iconTarget.classList.contains('rotate-180')) {
      this.dropdownTarget.style.height = "0px"
    } else {
      this.dropdownTarget.style.height = this.dropdownTarget.scrollHeight + "px"
    }
  }

  toggle() {
    if (this.dropdownTarget.style.maxHeight == '') {
      this.iconTarget.classList.remove('rotate-180')
      this.dropdownTarget.style.maxHeight = this.dropdownTarget.scrollHeight + "px"
    } else {
      this.iconTarget.classList.add('rotate-180')
      this.dropdownTarget.style.maxHeight = null
    }
  }

  addClickOut(element, icon) {
    document.addEventListener('click', function(event) {
      const isClickInsideElement = element.parentNode.contains(event.target);
      if (!isClickInsideElement) {
        element.style.maxHeight = null
        icon.classList.add('rotate-180')
      }
    })
  }
}
