import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    this.element.classList.remove('translate-y-full')
    setTimeout(() => {
      this.hideFlash()
    }, 3000)
  }

  hideFlash() {
    this.element.classList.add('-translate-x-full')
  }
}
