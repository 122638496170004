import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="photos"
export default class extends Controller {
  static targets = ['loadable', 'loader']

  showLoader() {
    this.loadableTarget.classList.remove('h-72')
    this.loadableTarget.classList.add('h-0')
    this.loaderTarget.classList.remove('h-0')
    this.loaderTarget.classList.add('h-72')
  }
}
