import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['endDate', 'currentExperience', 'form', 'cardContent']

  connect() {
    this.endDateVisibility();
  }

  endDateVisibility() {
    if (this.currentExperienceTarget.checked) {
      this.hide(this.endDateTarget)
    } else {
      this.show(this.endDateTarget)
    }
  }

  show(target) {
    target.classList.remove('d-none');
  }

  hide(target) {
    target.classList.add('d-none');
  }

  createExperience() {
    event.preventDefault();
    const url = this.formTarget.action;
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: new FormData(this.formTarget),
      success: data => {
        this.cardContentTarget.innerHTML = data.card_content;
      }
    })
  }
}
