import { Controller }     from "@hotwired/stimulus"
import Rails              from '@rails/ujs'

// Connects to data-controller="locations"
export default class extends Controller {
  static targets = ['textInput', 'nameInput', 'coordinatesInput', 'suggestions', 'form']

  suggest() {
    const query = this.textInputTarget.value
    Rails.ajax({
      type: 'GET',
      url: `/locations/suggest?query=${query}`,
      dataType: 'json',
      success: (data) => {
        this.suggestionsTarget.innerHTML = data
      }
    })
  }

  retrieve({ params: { mapboxId, spinners } }) {
    Rails.ajax({
      type: 'GET',
      url: `/locations/retrieve?mapbox_id=${mapboxId}`,
      dataType: 'json',
      success: (data) => {
        this.suggestionsTarget.style.maxHeight = '0px'
        this.coordinatesInputTarget.value = `${data['coordinates']['latitude']}, ${data['coordinates']['longitude']}`
        this.textInputTarget.value = data['name']
        this.nameInputTarget.value = data['name']
        this.submit(spinners)
      }
    })
  }

  submit(spinners) {
    if (spinners) {
      spinners.forEach((spinner) => {
        this.#replaceSpinner(spinner[0], spinner[1])
      })
    }
    Rails.fire(this.formTarget, 'submit')
  }

  #replaceSpinner(target, html) {
    document.getElementById(target).innerHTML = html
  }
}
