import { Controller } from "stimulus"
import Rails          from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'createBtn' ]
  static values = {
    url: String
  }

  connect() {
  }

  createConnection({params: {senderId, receiverId, validationText}}) {
    Rails.ajax({
      url: `${this.urlValue}?sender_id=${senderId}&receiver_id=${receiverId}`,
      type: 'POST',
      dataType: 'JSON',
      success: () => {
        this.createBtnTarget.innerText = validationText;
        document.getElementById(`contact-${receiverId}`).remove();
      }
    })
  }

  acceptConnection({params: {url, validationText}}) {
    Rails.ajax({
      url: url,
      type: 'PUT',
      dataType: 'JSON',
      success: () => {
        this.createBtnTarget.innerText = validationText;
      }
    })
  }

  rejectConnection({ params: {url, id} }) {
    Rails.ajax({
      url: url,
      type: 'PUT',
      dataType: 'JSON',
      success: () => {
        document.getElementById(`connections-wait-${id}`).remove();
      }
    })
  }

  cancelConnection({ params: {url, id} }) {
    Rails.ajax({
      url: url,
      type: 'DELETE',
      dataType: 'JSON',
      success: () => {
        document.getElementById(`connections-wait-${id}`).remove();
      }
    })
  }
}
