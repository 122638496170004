import { Controller } from "@hotwired/stimulus"
import Rails          from '@rails/ujs'

// Connects to data-controller="front--sections"
export default class extends Controller {
  static targets = ['form']

  connect() {
    Rails.fire(this.formTarget, 'submit')
  }
}
