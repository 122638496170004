import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat--container"
export default class extends Controller {
  static targets = ['container']

  connect() {
    this.setContainerHeight()
    window.visualViewport.addEventListener('resize', this.setContainerHeight())
  }

  setContainerHeight() {
    const navHeight = document.querySelector('#navbar').scrollHeight
    const headHeight = document.querySelector('#header').scrollHeight
    const height = window.visualViewport.height - navHeight - headHeight
    document.getElementById('chatContainer').style.height = `${height}px`
  }
}
