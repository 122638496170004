import { Controller } from "@hotwired/stimulus"
import Rails          from "@rails/ujs"

// Connects to data-controller="structure"
export default class extends Controller {
  addTag({ params: { url, id, taglist } }) {
    Rails.ajax({
      url: url,
      dataType: 'JSON',
      type: 'POST',
      success: () => {
        const target = document.getElementById(`${taglist}-${id}`)
        this.#toggleStyle(target)
      }
    })
  }

  removeTag({ params: { url, id, taglist } }) {
    Rails.ajax({
      url: url,
      dataType: 'JSON',
      type: 'POST',
      success: () => {
        const target = document.getElementById(`${taglist}-${id}`)
        this.#toggleStyle(target)
      }
    })
  }

  #toggleStyle(target) {
    target.classList.toggle('blue')
    target.classList.toggle('prio-low')
  }
}
