import { Controller } from "@hotwired/stimulus"
import Rails          from '@rails/ujs'

// Connects to data-controller="offers--filters"
export default class extends Controller {
  static targets = ['modal', 'form', 'select', 'contractType', 'orientationCategory']

  connect() {
    Rails.fire(this.formTarget, 'submit')
    this.showContractTypes()
    this.showOrientations()
  }

  toggleModal() {
    if (this.modalTarget.style.maxHeight) {
      document.body.classList.remove('noscroll')
      this.modalTarget.style.maxHeight = null
    } else {
      document.body.classList.add('noscroll')
      this.modalTarget.style.maxHeight = '100vh'
    }
  }

  showContractTypes() {
    const selectValue = this.selectTarget.value
    this.contractTypeTargets.forEach(tag => {
      if (tag.dataset.category == selectValue) {
        tag.classList.remove('hidden')
      } else {
        tag.getElementsByTagName('input')[0].checked = false
        tag.classList.add('hidden')
      }
    })
  }

  showOrientations() {
    const selectValue = this.selectTarget.value
    this.orientationCategoryTargets.forEach(category => {
      if (category.dataset.category === selectValue) {
        category.classList.remove('max-h-0')
      } else {
        Array.from(category.getElementsByTagName('input')).forEach(element => {
          element.checked = false
        })
        category.classList.add('max-h-0')
      }
    })
  }
}
