import { Application } from '@hotwired/stimulus'
import ContentLoader from 'stimulus-content-loader'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// External controllers
application.register('content-loader', ContentLoader)

export { application }
