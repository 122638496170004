import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'formTriggerer', 'form', 'resource' ]
  static values = {
    url: String
  }

  connect() {
    console.log('edit_resource controller connected');
  }

  update(event) {
    console.log('editResource triggered');
    event.preventDefault();
    const url = this.formTarget.action;
    fetch(url, {
      method: 'PATCH',
      headers: { 'Accept': 'text/html' },
      body: new FormData(this.formTarget)
    })
      .then(response => response.text())
      .then((data) => {
        this.resourceTarget.innerHTML = data;
      })
  }
}
