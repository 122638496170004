import { Controller } from "stimulus"
import Rails          from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'form' ]

  changeActionAndSubmit(event) {
    event.preventDefault()

    this.formTarget.action = '/offers/alerts'
    this.formTarget.method = 'POST'

    this.formTarget.submit()
  }
}
