import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'feedContainer' ]
  static values = {
    url: String,
  }

  connect() {
    console.log('users--feed controller connected');
    this.#getData();
  }

  #getData() {
    const url = this.urlValue;
    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      success: (data) => {
        this.feedContainerTarget.innerHTML = data.feed;
      }
    })
  }
}
