import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'contactsCard' ]
  static values = {
    url: String
  }

  connect() {
    console.log('users--network controller connected');

    Rails.ajax({
      type: 'GET',
      dataType: 'JSON',
      url: this.urlValue,
      success: (data) => {
        this.contactsCardTarget.innerHTML = data.contacts
      }
    })
  }

  getContacts({ params: { url } }) {
    console.log('getContacts triggered')

    this.#toggleSelected()

    Rails.ajax({
      type: 'GET',
      dataType: 'JSON',
      url: url,
      success: (data) => {
        this.contactsCardTarget.innerHTML = data.contacts
      }
    })
  }

  #toggleSelected() {
    event.target.parentNode.parentNode.querySelector('.selected').classList.remove('selected')
    event.target.classList.add('selected')
  }
}
