import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = [ 'accordion' ]

  connect() {
    this.accordionTargets.forEach((accordion) => {
      const title = accordion.querySelector(".accordion-menu");
      const content = accordion.querySelector(".accordion-content");
      title.addEventListener('click', (event) => {
        if (content.style.maxHeight) {
          const content = accordion.querySelector(".accordion-content")
          content.style.maxHeight = null
          title.classList.remove('open');
          this.rotateArrow(accordion, false)
        } else {
          this.accordionTargets.forEach((accordion) => this.closeAccordion(accordion))
          this.openAccordion(accordion);
        }
      })
    });
  }

  closeAccordion(accordion) {
    const content = accordion.querySelector(".accordion-content");
    const title = accordion.querySelector(".accordion-menu");
    content.style.maxHeight = null;
    title.classList.remove('open');
    this.rotateArrow(accordion, false)
  }

  openAccordion(accordion) {
    const title = accordion.querySelector(".accordion-menu");
    const content = accordion.querySelector(".accordion-content");
    content.style.maxHeight = content.scrollHeight + "px";
    title.classList.add('open');
    this.rotateArrow(accordion)
  };

  rotateArrow(accordion, rotate = true) {
    const arrow = accordion.querySelector('.accordion-arrow');
    if (rotate) {
      arrow.classList.add("rotate-180", "duration-500", "ease-out")
    } else {
      arrow.classList.remove("rotate-180")
    }
  }
}
