import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--sidebar"
export default class extends Controller {
  static targets = ['sidebar', 'chevron']

  toggle() {
    if (this.sidebarTarget.style.maxWidth === "") {
      this.sidebarTarget.style.maxWidth = "250px"
      this.chevronTarget.style.rotate = '180deg'
    } else {
      this.sidebarTarget.style.maxWidth = null
      this.chevronTarget.style.rotate = '0deg'
    }
  }
}
