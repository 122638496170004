import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'sidebar', 'icon' ]
  static values = {
    withLogo: Boolean
  }

  toggle() {
    if (this.sidebarTarget.classList.contains('show')) {
      this.sidebarTarget.classList.remove('show')
    } else {
      this.sidebarTarget.classList.add('show')
    }
  }

  slideFromRight() {
    console.log('slideFromRight triggered')
    console.log(this.withLogoValue)
    this.sidebarTarget.classList.toggle('translate-x-full')
    if (this.withLogoValue) {
      this.changeIcon()
    }
  }

  changeIcon() {
    console.log(this.iconTarget)
    this.iconTargets.forEach(icon => {
      icon.classList.toggle('hidden')
    })
  }
}
