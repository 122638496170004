import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users--search"
export default class extends Controller {
  static values = {
    orientations: String,
    skills: String,
  }
  static targets = ['select', 'orientation', 'orientationsContainer', 'skill', 'skillsContainer']

  connect() {
    this.showOrientations()
    this.showSkills()
  }

  showOrientations() {
    const selectValue = this.selectTarget.value
    if (this.orientationsValue.split(',').includes(selectValue)) {
      this.orientationsContainerTarget.style.maxWidth = '300px'
      this.orientationsContainerTarget.classList.remove('overflow-hidden')
      this.orientationTargets.forEach(tag => {
        if (tag.dataset.category == selectValue) {
          tag.classList.remove('hidden')
        } else {
          tag.getElementsByTagName('input')[0].checked = false
          tag.classList.add('hidden')
        }
      })
    } else {
      this.orientationsContainerTarget.style.maxWidth = null
      this.orientationsContainerTarget.classList.add('overflow-hidden')
      this.orientationTargets.forEach(tag => {
        tag.getElementsByTagName('input')[0].checked = false
      })
    }
  }

  showSkills() {
    const selectValue = this.selectTarget.value
    if (this.skillsValue.split(',').includes(selectValue)) {
      this.skillsContainerTarget.style.maxWidth = '300px'
      this.skillsContainerTarget.classList.remove('overflow-hidden')
      this.skillTargets.forEach(tag => {
        if (tag.dataset.category == selectValue) {
          tag.classList.remove('hidden')
        } else {
          tag.getElementsByTagName('input')[0].checked = false
          tag.classList.add('hidden')
        }
      })
    } else {
      this.skillsContainerTarget.style.maxWidth = null
      this.skillsContainerTarget.classList.add('overflow-hidden')
      this.skillTargets.forEach(tag => {
        tag.getElementsByTagName('input')[0].checked = false
      })
    }
  }
}
