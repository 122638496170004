import { Controller } from "@hotwired/stimulus"
import Rails          from "@rails/ujs"

// Connects to data-controller="feedback--ticket-chip"
export default class extends Controller {
  static targets = [ 'chipSign', 'formContainer', 'form' ]

  toggleForm() {
    if (this.chipSignTarget.classList.contains('rotate-45')) {
      this.#openForm()
    } else {
      this.#closeForm()
    }
  }

  #openForm() {
    this.chipSignTarget.classList.remove('rotate-45', 'scale-110')
    this.formContainerTarget.classList.add('h-0')
    this.formContainerTarget.classList.remove('h-400')
  }

  #closeForm() {
    this.chipSignTarget.classList.add('rotate-45', 'scale-110')
    this.formContainerTarget.classList.remove('h-0')
    this.formContainerTarget.classList.add('h-400')
  }
}
