import { Controller } from "@hotwired/stimulus"
import Rails          from "@rails/ujs"

// Connects to data-controller="forms"
export default class extends Controller {
  static targets = ['form']

  submit({ params: { spinners } }) {
    if (spinners) {
      spinners.forEach((spinner) => {
        this.#replaceSpinner(spinner[0], spinner[1])
      })
    }
    Rails.fire(this.formTarget, 'submit')
  }

  #replaceSpinner(target, html) {
    document.getElementById(target).innerHTML = html
  }
}
