import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ['photo']

  connect() {
    this.photoTargets[0].classList.add('active')
    this.photoTargets[0].classList.remove('after')
    document.getElementById('prevButton').disabled = true
  }

  prev() {
    const active = document.getElementsByClassName('active')[0]
    if (parseInt(active.id) - 1 < 0) {
      document.getElementById('prevButton').disabled = true
    } else {
      document.getElementById('nextButton').disabled = false
      const newActiveId = parseInt(active.id) - 1
      document.getElementById(newActiveId).classList.add('active')
      document.getElementById(newActiveId).classList.remove('before')
      active.classList.remove('active')
      active.classList.add('after')
    }
  }

  next() {
    const active = document.getElementsByClassName('active')[0]
    if (active.id <= this.photoTargets.length - 1) {
      document.getElementById('prevButton').disabled = false
      const newActiveId = parseInt(active.id) + 1
      document.getElementById(newActiveId).classList.add('active')
      document.getElementById(newActiveId).classList.remove('after')
      active.classList.remove('active')
      active.classList.add('before')
    } else {
      document.getElementById('nextButton').disabled = true
    }
  }
}
