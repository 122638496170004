import { Controller } from "@hotwired/stimulus"
import Rails          from "@rails/ujs"
import consumer from '../channels/consumer';

// Connects to data-controller="conversation"
export default class extends Controller {
  static targets = ['inputMessage']
  static values = {
    userId: String,
    conversationId: String
  }

  connect() {
    document.body.style.overflow = 'hidden'
    // scroll to bottom on connect
    const messages = document.getElementById('messages')
    messages.scrollTo(0, messages.scrollHeight)
    this.#updateStatus(this.userIdValue, this.conversationIdValue, 'connect')
    // check if user disconnect by qui tab or close browser
    this.#channel_sub()
    // scroll bottom when new message
    window.addEventListener('DOMNodeInserted', function(event) {
      messages.scrollTo(0, messages.scrollHeight)
    })
  }

  #channel_sub() {
    consumer.subscriptions.create("StatusChannel");
  }

  #updateStatus(userId, conversationId, action) {
    Rails.ajax({
      url: `/users/${userId}/conversations/${conversationId}/update_status?triggered_by=${action}`,
      type: 'GET',
    })
  }

  reset(){
    // remove value of input
    this.inputMessageTarget.value = ''
  }

  disconnect() {
    // use to disconnect user when he return by click on button
    this.#updateStatus(this.userIdValue, this.conversationIdValue, 'disconnect')
  }
}
