import { Controller } from "stimulus"
import Rails          from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'form', 'input', 'list' ]

  filter() {
    const url = `${this.formTarget.action}?query=${this.inputTarget.value}`
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: data => {
        this.listTarget.innerHTML = data.data
      }
    })
  }
}
