import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="front--tabs"
export default class extends Controller {
  static targets = ['tabHead', 'tab']

  connect() {
    const firstTabId = this.tabHeadTargets[0].dataset.tabId
    this.show(firstTabId)
  }

  select({ params: { id } }) {
    this.show(`${id}`)
  }

  show(id) {
    var head = this.tabHeadTargets.find((th) => th.dataset.tabId === id )
    this.selectHead(head)
    this.tabTargets.forEach((tab) => {
      var tabId = tab.dataset.tabId
      if (tabId === id) {
        tab.style.maxHeight = tab.scrollHeight + 'px'
      } else {
        tab.style.maxHeight = null
      }
    })
  }

  selectHead(element) {
    this.tabHeadTargets.forEach((head) => {
      head.classList.remove('selected')
    })
    element.classList.add('selected')
  }
}
