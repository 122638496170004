import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['expandable']

  toggleHeight() {
    if (this.expandableTarget.style.maxHeight) {
      this.expandableTarget.style.maxHeight = null
      event.target.innerText = 'Voir plus +'
    } else {
      this.expandableTarget.style.maxHeight = this.expandableTarget.scrollHeight + "px";
      event.target.innerText = 'Voir moins -'
    }
  }

  toggleHeightWoutTextChange() {
    if (this.expandableTarget.style.maxHeight) {
      this.expandableTarget.style.maxHeight = null
    } else {
      this.expandableTarget.style.maxHeight = this.expandableTarget.scrollHeight + "px";
    }
  }

  toggleHeightWIconSpin() {
    if (this.expandableTarget.style.maxHeight) {
      this.expandableTarget.style.maxHeight = null
      event.target.querySelector('#add').classList.remove('rotate-45', 'scale-110')
    } else {
      this.expandableTarget.style.maxHeight = this.expandableTarget.scrollHeight + "px";
      event.target.querySelector('#add').classList.add('rotate-45', 'scale-110')
    }
  }

  replaceContent() {
    const elements = this.element.getElementsByClassName('content')
    for (let element of elements) {
      element.classList.toggle('hidden')
    }
  }
}
