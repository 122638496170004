import { Controller } from "@hotwired/stimulus"
import Rails          from '@rails/ujs'

// Connects to data-controller="chat--new"
export default class extends Controller {
  static targets = ['form', 'nameInput', 'checkbox']

  submit() {
    Rails.fire(this.formTarget, 'submit')
  }

  contactsCount() {
    const count = this.checkboxTargets.filter(checkbox => {
      return checkbox.checked
    }).length

    if (count > 1) {
      this.nameInputTarget.style.maxHeight = this.nameInputTarget.scrollHeight + 'px'
    } else {
      this.nameInputTarget.style.maxHeight = null
    }
  }
}
