import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user"
export default class extends Controller {
  static targets = ['showMotherTongue', 'editMotherTongue']

  editMotherTongue() {
    this.editMotherTongueTarget.classList.remove('w-0')
    this.showMotherTongueTarget.classList.add('w-0')
  }
}
