import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message"
export default class extends Controller {
  static targets = ['message']

  connect() {
    let currentUserId = document.getElementById('data').dataset.conversationUserIdValue
    this.messageTargets.forEach((message) => {
      if (message.dataset.id === currentUserId) {
        message.classList.add('items-end')
        message.querySelector('#content').classList.add('flex-row-reverse')
        message.querySelector('#body').classList.add('bg-primary-blue', 'text-white', 'rounded-tl-lg')
        message.querySelector('#body').classList.remove('bg-grey-300', 'text-primary-blue', 'rounded-tr-lg')
      }
    })
  }
}
