import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat--box"
export default class extends Controller {
  static targets = ['list', 'textArea']

  connect() {
    this.textAreaHeight()
    const list = this.listTarget
    const textArea = this.textAreaTarget
    textArea.style.lineHeight = '28px'
    this.scrollToBottom()
    window.addEventListener('DOMNodeInserted', function(){
      textArea.value = ''
      textArea.style.height = '36px'
      list.scrollTo(0, list.scrollHeight)
    })
  }

  scrollToBottom() {
    this.listTarget.scrollTo(0, this.listTarget.scrollHeight)
  }

  textAreaHeight() {
    const lineHeight = parseInt(this.textAreaTarget.style.lineHeight.split('px')[0])
    const scrollHeight = this.textAreaTarget.scrollHeight
    const ratio = parseInt(scrollHeight / lineHeight)
    if (ratio == 2) {
      this.textAreaTarget.style.height = '64px'
    } else if (ratio >= 3) {
      this.textAreaTarget.style.height = '92px'
    } else {
      this.textAreaTarget.style.height = '36px'
    }
    if (this.textAreaTarget.value.length < 40) {
      this.textAreaTarget.style.height = '36px'
    }
  }

  setFocus() {
    this.textAreaTarget.focus()
  }
}
