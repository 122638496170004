import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="front--accordion"
export default class extends Controller {
  static targets = ['fold', 'chevron']

  open() {
    this.foldTargets.forEach((fold) => {
      const chevron = document.getElementById(`chevron_${fold.dataset.index}`)
      if (event.target.dataset.index == fold.dataset.index && fold.style.maxHeight == 0) {
        fold.style.maxHeight = fold.scrollHeight + 'px'
        chevron.classList.remove('rotate-180')
      } else {
        fold.style.maxHeight = null
        chevron.classList.add('rotate-180')
      }
    })
  }
}
