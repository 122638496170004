import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cards--component"
export default class extends Controller {
  static targets = ['content']

  replaceContent() {
    this.contentTargets.forEach(target => {
      target.classList.toggle('hidden')
    })
    event.currentTarget.classList.toggle('rotate-45')
  }

  toggleForm() {
    if (this.contentTarget.style.maxHeight) {
      this.contentTarget.style.maxHeight = null
      event.currentTarget.classList.remove('rotate-45')
    } else {
      this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + "px";
      event.currentTarget.classList.add('rotate-45')
    }
  }
}
